import React from "react"
import { Link } from "gatsby"

import Template from "../components/Template"
// import IntroImage2 from "../components/introimage2"
import SEO from "../components/seo"
// import style from "./1-the-gokyo-ri.module.scss"

const SecondPage = () => (
  <Template>
    <SEO title="2. Animalkind" />
  </Template>
)

export default SecondPage
